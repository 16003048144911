<template>
  <div class="mgAll10">
    <el-dialog class="custom-dialog" :title="'菜单'+(formType?'编辑':'添加')" :visible.sync="dialogVisible" width="520px"
      :append-to-body="true" @close="closeAlert">
      <el-form ref="form" :rules="rules" :model="form" label-width="90px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="菜单名称" prop="menuName" class="custom-form-item">
              <el-input v-model="form.menuName" placeholder="请输入菜单名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单编码" prop="menuCode" class="custom-form-item">
              <el-input v-model="form.menuCode" :disabled="formType" placeholder="请输入菜单编码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="父级菜单" prop="menuCodeFid" class="custom-form-item">
          <el-cascader ref="cascader" class="width-100" :disabled="formType" :options="menuOptions"
            v-model="form.menuCodeFid" :props="{ checkStrictly: true,label:'title'}" clearable>
          </el-cascader>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="菜单地址" prop="menuUrl" class="custom-form-item">
              <el-input v-model="form.menuUrl" placeholder="请输入菜单地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单路径" prop="menuPath" class="custom-form-item">
              <el-input v-model="form.menuPath" placeholder="请输入菜单路径"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="菜单序号" prop="menuSort" class="custom-form-item">
              <el-input v-model="form.menuSort" placeholder="请输入菜单序号" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单图标" prop="mennIcon" class="custom-form-item">
              <el-input v-model="form.mennIcon" placeholder="请输入菜单图标"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="管理员可见" prop="menuAdmin" class="custom-form-item">
              <el-radio-group v-model="form.menuAdmin">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单类型" prop="menuType" class="custom-form-item">
              <el-radio-group v-model="form.menuType" :disabled="formType">
                <el-radio :label="0">菜单</el-radio>
                <el-radio :label="1">权限</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="菜单状态" prop="menuState" class="custom-form-item">
              <el-radio-group v-model="form.menuState">
                <el-radio :label="true">显示</el-radio>
                <el-radio :label="false">隐藏</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否缓存" prop="menuCache" class="custom-form-item">
              <el-radio-group v-model="form.menuCache">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
        </el-button>
        <el-button @click="closeAlert">取 消</el-button>
      </div>
    </el-dialog>
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <div>
        <!-- 搜索 -->
        <el-input v-model="searchForm.name" placeholder="菜单名称" class="mgB10 custom-form-input"></el-input>
        <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
        </el-button>
        <el-button type="success" @click="add" plain class="mgL10 mgB10">添加
        </el-button>
        <!-- 表格 -->
        <el-table :data="tableData" border v-loading="loading" row-key="id">
          <el-table-column prop="menuName" :show-overflow-tooltip="true" header-align="center" align="center"
            label="菜单名称"></el-table-column>
          <el-table-column prop="menuCode" :show-overflow-tooltip="true" header-align="center" align="center"
            label="菜单编码"></el-table-column>
          <el-table-column prop="menuLevel" :show-overflow-tooltip="true" header-align="center" align="center"
            label="菜单等级">
          </el-table-column>
          <el-table-column prop="menuType" :show-overflow-tooltip="true" header-align="center" align="center"
            label="菜单类型" width="120">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.menuType==0">菜单</el-tag>
              <el-tag type="success" v-else>权限</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="menuSort" :show-overflow-tooltip="true" header-align="center" align="center"
            label="菜单序号">
          </el-table-column>
          <el-table-column prop="menuUrl" :show-overflow-tooltip="true" header-align="center" align="center"
            label="页面地址">
          </el-table-column>
          <el-table-column prop="menuPath" :show-overflow-tooltip="true" header-align="center" align="center"
            label="页面路径">
          </el-table-column>
          <el-table-column prop="mennIcon" :show-overflow-tooltip="true" header-align="center" align="center"
            label="显示图标" width="90">
            <template slot-scope="scope">
              <i :class="scope.row.mennIcon"></i>
            </template>
          </el-table-column>
          <el-table-column prop="menuAdmin" :show-overflow-tooltip="true" header-align="center" align="center"
            label="管理员显示" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.menuAdmin" @change="changeAdmin(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="menuState" :show-overflow-tooltip="true" header-align="center" align="center"
            label="菜单状态" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.menuState" @change="changeState(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="menuCache" :show-overflow-tooltip="true" header-align="center" align="center"
            label="页面是否缓存" width="120">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.menuCache">是</el-tag>
              <el-tag type="success" v-else>否</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="menuLiang" :show-overflow-tooltip="true" header-align="center" align="center"
            label="粮库必选" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.menuLiang" @change="changeLiang(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button @click.native.prevent="editRow(scope.row)" size="mini" class="custom-table-btn">
                编辑
              </el-button>
              <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini" class="custom-table-btn">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach">
        </table-page>
      </div>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: "systemMenu",
    data() {
      return {
        tableData: [],
        loading: false,
        searchForm: {
          name: "",
          page: 1,
          limit: 10,
        },
        total: 0,
        menuOptions: [],
        dialogVisible: false,
        formType: false,
        loadingAlert: false,
        form: {
          menuName: "",
          menuCode: "",
          menuUrl: "",
          menuPath: "",
          mennIcon: "",
          menuSort: "",
          menuType: 0,
          menuAdmin: true,
          menuState: false,
          menuLiang: false,
          menuCache: true,
          menuCodeFid: ""
        },
        rules: {
          menuPath: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          menuCache: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          menuName: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          menuCode: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          menuUrl: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          mennIcon: [{
            required: false,
            message: '可为空',
            trigger: 'blur'
          }],
          menuSort: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          menuType: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          menuAdmin: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          menuState: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          menuCodeFid: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
        }
      }
    },
    mounted() {
      this.search();
      this.menuTreeAll()
    },
    methods: {
      search() {
        this.getData(1, this.searchForm.limit)
      },
      getData(page, limit) {
        this.loading = true;
        this.$axios.Get(this.$api.menuListPage, {
          name: this.searchForm.name,
          page: page,
          limit: limit
        }).then(res => {
          this.searchForm.limit = limit
          this.searchForm.page = page
          this.tableData = res.data;
          this.total = res.count
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      feach(e) {
        this.getData(e.page, e.limit)
      },
      changeAdmin(e) {
        this.$axios.Post(this.$api.menuModify, {
          "id": e.menuId,
          "show": e.menuAdmin
        }).then(() => {}).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          e.menuAdmin = !e.menuAdmin
        })
      },
      changeState: function(e) {
        this.$axios.Post(this.$api.menuModifyState, {
          "id": e.menuId,
          "state": e.menuState
        }).then(() => {}).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          e.menuState = !e.menuState
        })
      },
      changeLiang(e) {
        this.$axios.Post(this.$api.menuModifyLiang, {
          "id": e.menuId,
          "state": e.menuLiang
        }).then(() => {}).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          e.menuLiang = !e.menuLiang
        })
      },
      editRow(row) {
        this.formType = true;
        this.dialogVisible = true;
        let data = JSON.parse(JSON.stringify(row))
        let ids = data.menuCodes.substring(1, data.menuCodes.length - 1).split("],[")
        data["menuCodeFid"] = ids
        this.form = data
      },
      add() {
        this.dialogVisible = true;
      },
      //关闭
      closeAlert() {
        this.$refs["form"].resetFields();
        this.form.menuId = "";
        this.loadingAlert = false;
        this.formType = false;
        this.dialogVisible = false;
        this.menuTreeAll()
      },
      //表单提交
      onSubmit: function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.formType ? this.editUser() : this.addUser()
          } else {
            return false;
          }
        });
      },
      //添加信息
      addUser() {
        this.loadingAlert = true
        let params = JSON.parse(JSON.stringify(this.form))
        params["menuCodeFid"] = params.menuCodeFid[params.menuCodeFid.length - 1]
        this.$axios.Post(this.$api.menuAdd, params).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //编辑信息
      editUser() {
        this.loadingAlert = true
        let params = JSON.parse(JSON.stringify(this.form))
        params["menuCodeFid"] = params.menuCodeFid[params.menuCodeFid.length - 1]
        this.$axios.Post(this.$api.menuEdit, params).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.closeAlert();
          this.getData(this.searchForm.page, this.searchForm.limit)
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //删除表格数据
      delRow: function(row) {
        this.$confirm('确认要删除该条信息吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.Post(this.$api.menuDel, {
            "id": row.menuId
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getData(this.searchForm.page, this.searchForm.limit)
          }).catch((err) => {
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        }).catch(() => {});
      },
      //获取菜单数据
      menuTreeAll: function() {
        this.$axios.Get(this.$api.menuTreeAll, {}).then(res => {
          let data = res.data
          this.formatMenuTree(data)
          this.menuOptions = [{
            "title": "顶级",
            "value": "0",
            "children": data
          }]
        })
      },
      //格式化
      formatMenuTree(data) {
        data.forEach(item => {
          item["value"] = item.id
          if (item.children.length > 0) {
            this.formatMenuTree(item.children)
          } else {
            delete item.children
          }
        })
      }
    },
    components: {
      "table-page": () => import('@/components/TablePage')
    }
  }
</script>

<style lang="less">
  .custom-card+.custom-card {
    margin-top: 10px;
  }
</style>
